
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import StandardFlag from "@/components/scope/standard-flag.vue";
import { Pageniation, TableRowsSelect, Table } from "@/utils/decorator";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { splitThousands } from "@/utils/common";

import SwitchStatus from "@/components/scope/switch-status.vue";
import t from "@common/src/i18n/t";

const base = namespace("base");
const category = namespace("service-category");
import FileUpload from "@/components/scope/file-upload.vue";
@Component({
  components: { FileUpload , SwitchStatus}
})
@Pageniation("loadListData")
@Table("loadListData")

export default class Service extends Vue {
  @base.Action getDictionaryList;
  @category.Action pageQueryCategory;
  @category.Action setServiceCategoryIcon;
  @category.Action serviceCategoryEnable;
  @category.Action serviceCategoryDisable;
  @category.Action serviceCategorySortUp;
  @category.Action serviceCategorySortDown;
  searchForm = {
    serviceCategoryName: ""
  };

  serviceCategoryList: any[] = [];
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  handleSearch() {
    this.mix_pageNum = 1;
    this.loadListData();
  }
  created() {
    this.init();
    this.loadListData();
  }
  @Pageniation.LoadDataMethod()
  loadListData() {
    return this.pageQueryCategory(
        Object.assign(this.searchForm, {
          pageSize: this.mix_pageSize,
          pageNum: this.mix_pageNum,
        })
    ).then(data => {
      this.serviceCategoryList = data.data.list;
      return data;
    });
  }
  iconChangeDialogVisible: boolean = false;

  changeIconObj = {
    serviceCategoryCode: "",
    iconUrl: ""
  };

  handleChangeIcon(selectedRow){
    this.changeIconObj.serviceCategoryCode = selectedRow.serviceCategoryCode;
    this.iconChangeDialogVisible = true;
  }

  iconChageHandleClose() {
    console.log("关闭")
    this.changeIconObj.serviceCategoryCode = "";
    this.changeIconObj.iconUrl = "";
    (this.$refs.iconUpload as any).clearFiles();
  }

  confirmUpdateIcon() {
    return this.setServiceCategoryIcon(this.changeIconObj)
      .then(data => {
        this.$message.success("修改成功");
        this.loadListData();
        this.iconChangeDialogVisible = false;
      })
      .catch(e => {
        this.$message.error("修改失败");
      });
  }

  getStoreTypeStr(bussinessTypes){
    let typeName = "";
    bussinessTypes.forEach(type => {
      typeName += this.storeTypeMap.get(type.businessType+"");
      typeName += ",";
    });
    if (bussinessTypes.length > 0) {
      typeName = typeName.substring(0, typeName.length-1);
    }
    return typeName;
  }

  getServiceClassStr(serviceClass) {
    return this.serviceTypeMap.get(serviceClass + "");
  }

  editHandle(row) {
    this.$router.push(`/management/service-category-edit/` + row.serviceCategoryCode);
  }

  handleSortUp(row) {
    this.$confirm('是否确认上移？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.serviceCategorySortUp({
        categoryCode: row.serviceCategoryCode
      }).then(res => {
        if (res.code == 0) {
          this.$message.success("操作成功");
          this.loadListData();
        } else {
          this.$message.error("操作失败");
        }
      });
    });
  }

  handleSortDown(row) {
    this.$confirm('是否确认下移？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.serviceCategorySortDown({
        categoryCode: row.serviceCategoryCode
      }).then(res => {
        if (res.code == 0) {
          this.$message.success("操作成功");
          this.loadListData();
        } else {
          this.$message.error("操作失败");
        }
      });
    });
  }

  confirmUpdateStatus(status, selectedRow?) {
    if (status == 0) {
      return this.serviceCategoryDisable({
        categoryCode: selectedRow.serviceCategoryCode
      })
        .then(data => {
          this.$message.success(
            status === 1
              ? (this.$t("setting.enable-successful") as string)
              : (this.$t("setting.disable-success") as string)
          );
          this.loadListData();
        })
        .catch(e => {
          if (e.code === "speedbiz.system.pay-method-least-one-valid") {
            this.$message.error(t("setting.valid-method-payment"));
          }
        });
    }else if (status == 1) {
      return this.serviceCategoryEnable({
        categoryCode: selectedRow.serviceCategoryCode
      })
        .then(data => {
          this.$message.success(
            status === 1
              ? (this.$t("setting.enable-successful") as string)
              : (this.$t("setting.disable-success") as string)
          );
          this.loadListData();
        })
        .catch(e => {
          if (e.code === "speedbiz.system.pay-method-least-one-valid") {
            this.$message.error(t("setting.valid-method-payment"));
          }
        });
    }
  }

  storeTypesList: any = [];
  storeTypeMap: any = {};

  serviceTypeList: any =[];
  serviceTypeMap: any = {};


  init() {
    this.getDictionaryList("store_type").then(data => {
      this.storeTypesList = data;
      this.storeTypeMap = new Map(this.storeTypesList.map(item => [item.value, item.label]));
    });
    this.getDictionaryList("service_class").then(data => {
      this.serviceTypeList = data;
      this.serviceTypeMap = new Map(this.serviceTypeList.map(item => [item.value, item.label]));
      console.log(this.serviceTypeMap);
    });
  }
}
